<template>
  <!-- 新增频道配置 -->
  <div>
    <!-- 填写表单 -->
    <el-form ref="ruleForm" :model="ruleForm" label-width="180px" :rules="rules">
      <el-form-item label="频道名称" prop="name">
        <el-input v-model="ruleForm.name" placeholder="请输入频道名称" size="mini" style="width: 300px"></el-input>
      </el-form-item>
      <el-form-item label="是否启用">
        <el-radio-group v-model="ruleForm.whetherNot">
          <el-radio :label="1">关闭</el-radio>
          <el-radio :label="2">启用</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item label="序号" prop="serialNumber">
        <el-input v-model="ruleForm.serialNumber" placeholder="请输入标题" size="mini" style="width: 300px"></el-input>
      </el-form-item>
      <el-form-item label="备注">
        <el-input v-model="ruleForm.remarks" type="textarea" placeholder="请输入标题" :rows="4" style="width: 600px"></el-input>
      </el-form-item>
      <el-form-item label="">
        <div>
          <el-button type="" @click="getBack">返回</el-button>
          <el-button type="warning" @click="superList">保存</el-button>
        </div>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
export default {
  data() {
    return {
      ruleForm: {
        name: '', // 频道名称
        whetherNot: 1, // 是否启用
        serialNumber: 0, // 序号
        remarks: '', // 备注
        id: ''
      },
      //   验证规则
      rules: {
        name: [{ required: true, message: '请输入频道名称', trigger: 'blur' }],
        serialNumber: [{ required: true, message: '请输入序号', trigger: 'blur' }]
      }
    }
  },
  methods: {
    superList() {
      this.axios
        .post('/admin/channel/addChannel', {
          name: this.ruleForm.name,
          sort: this.ruleForm.serialNumber,
          remark: this.ruleForm.remarks
        })
        .then(res => {
          console.log(res)
          this.$message({
            type: 'success',
            message: '添加成功'
          })
          this.getBack()
        })
        .catch(err => {})
    },
    //   返回上一页面
    getBack() {
      this.$router.go(-1)
    },
    // 保存
    submitForm(formName) {
      console.log(this.ruleForm)
      this.$refs[formName].validate(valid => {
        if (valid) {
          alert('submit!')
        } else {
          console.log('error submit!!')
          return false
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped></style>
